import { type Identifier } from 'react-admin';

import { API_HANDLERS } from '@/api';

export const setCompanyPsp = async (
  companyId: Identifier,
  pspId: Identifier,
  onSuccess: () => void,
  // eslint-disable-next-line
  onError: (error: any) => void,
): Promise<boolean> => {
  try {
    const response = await API_HANDLERS.COMPANY.SET_COMPANY_PSP(companyId, pspId);

    onSuccess();
    return response && true;
  } catch (error) {
    onError(error);
    return Promise.reject(new Error(`Set Company Psp error: ${error}`));
  }
};
