import { useCallback, useEffect, useState } from 'react';
import {
  List,
  useList,
  ListContextProvider,
  useTranslate,
  TextField,
  Datagrid,
  useGetRecordId,
  FunctionField,
  Identifier,
  RaRecord,
  useNotify,
} from 'react-admin';
import VisibilityIcon from '@mui/icons-material/Visibility';

import { companiesDataProvider } from '@/dataProvider';
import { ListButton } from '@/components/ListButton';
import {
  ICompanyPspItemResponseParams,
  CompanyEditFormMode,
  CompanyEditFormOptions,
} from '@/types';

interface ICompanyPspListProps {
  onChangeMode: (type: CompanyEditFormMode, options?: CompanyEditFormOptions) => void;
}

function CompanyPspList({ onChangeMode }: ICompanyPspListProps): JSX.Element {
  const t = useTranslate();
  const notify = useNotify();
  const [pspList, setPspList] = useState<ICompanyPspItemResponseParams[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const companyId = useGetRecordId();

  const getPspListByCompany = useCallback(() => {
    companiesDataProvider.getPspList(companyId).then(data => {
      setPspList(data);
      setIsLoading(false);
    });
  }, []);

  useEffect(() => {
    getPspListByCompany();
  }, []);

  const listContext = useList({ data: pspList, isLoading });

  const handleActivatePsp = useCallback(async (record: RaRecord<Identifier>) => {
    setIsLoading(true);

    companiesDataProvider.setCompanyPsp(
      companyId,
      record.id,
      () => {
        getPspListByCompany();
      },
      // eslint-disable-next-line
      (error: any) => {
        if (error && error?.response?.data?.message) {
          notify(error.response.data.message);
        }
      },
    );
  }, []);

  const handleShowPspInfo = useCallback((record: RaRecord<Identifier>) => {
    onChangeMode('psp', { pspId: record.id });
  }, []);

  return (
    <List actions={false} pagination={false}>
      <ListContextProvider value={listContext}>
        <Datagrid bulkActionButtons={false}>
          <TextField source="name" label="resources.psp.psp_name" sortable={false} />
          <FunctionField
            label="resources.company.status"
            sortable={false}
            render={(record: { is_active: boolean }) =>
              t(`resources.psp.${record?.is_active ? 'status_active' : 'status_not_active'}`)
            }
          />
          <ListButton
            title={t('resources.psp.show_details')}
            onClick={handleShowPspInfo}
            icon={<VisibilityIcon />}
          />
          <ListButton
            title={t('resources.psp.activate_psp')}
            disableSourceField="is_active"
            onClick={handleActivatePsp}
          />
        </Datagrid>
      </ListContextProvider>
    </List>
  );
}

export default CompanyPspList;
