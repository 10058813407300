import ukrainianMessages from 'ra-language-ukrainian';
import { TranslationMessages } from 'react-admin';

const customUkrainianMessages: TranslationMessages = {
  ...ukrainianMessages,
  ra: {
    ...ukrainianMessages.ra,
    page: {
      ...ukrainianMessages.ra.page,
      dashboard: 'Дешборд',
    },
  },
  common: {
    yes: 'Так',
    no: 'Ні',
  },
  auth: {
    ...ukrainianMessages.ra.auth,
    email: 'Введіть електронну адресу',
    forgot_password: 'Забули пароль',
    password: 'Введіть пароль',
    sign_in: 'Увійти',
    errors: {
      email: 'Будь ласка, введіть дійсну адресу електронної пошти.',
      email_not_empty: 'Поле Емейл не може бути порожнім.',
      otp_attempts_exceeded: 'Ви перевищили ліміт запитів. Зверніться в службу підтримки.',
      password_symbol_validation:
        'Пароль повинен містити як мінімум один знак в верхньому регістрі, нижньому та цифру.',
      password_min_length: 'Пароль повинен містити не менше 8 символів.',
      password_not_empty: 'Поле Пароль не може бути порожнім.',
      password_not_valid: 'Хибний пароль.',
      user_not_found: 'Не знайдено такого користувача. Перевірте введені дані.',
    },
  },
  forgot_password: {
    new_password: 'Новий пароль',
    confirm_new_password: 'Підтвердити новий пароль',
    change_password_btn: 'Змінити пароль',
    confirm_email_btn: 'Надіслати листа',
    errors: {
      required_field: "Це поле є обов'язковим",
      invalid_email: 'Недійсна електронна адреса. Перевірте свої дані та повторіть спробу.',
      invalid_email_address: 'Невірна електронна адреса',
      invalid_password: 'Пароль не змінено',
      password_doesnt_match: 'Пароль не збігається',
      valid_email: 'Електронна пошта дійсна. Ми надіслали вам листа з інструкціями.',
      valid_password: 'Пароль змінено',
    },
  },
  labels: {
    email: 'Email',
  },
  pos: {
    cancel: 'Скасувати',
    close: 'Закрити',
    search: 'Пошук',
    configuration: 'Налаштування',
    language: 'Мова',
    theme: {
      name: 'Тема',
      light: 'Світла',
      dark: 'Темна',
    },
    dashboard: {
      monthly_revenue: 'Місячний дохід',
      month_history: 'Історія доходів за 30 днів',
      new_orders: 'Нові замовлення',
      pending_reviews: 'Очікуються відгуки',
      all_reviews: 'Переглянути всі відгуки',
      new_customers: 'Нові клієнти',
      all_customers: 'Переглянути всіх клієнтів',
      pending_orders: 'Замовлення в обробці',
      order: {
        items: 'від %{customer_name}, один товар |||| від %{customer_name}, %{nb_items} товарів',
      },
      welcome: {
        title: 'Ласкаво просимо до демо електронної комерції react-admin',
        subtitle:
          "Це адміністративний інтерфейс уявного магазину постерів. Не соромтеся досліджувати та змінювати дані - вони локальні для вашого комп'ютера і будуть скинуті кожного разу при перезавантаженні.",
        ra_button: 'сайт react-admin',
        demo_button: 'Джерело цього демо',
      },
    },
    menu: {
      catalog: 'Каталог',
      change_password: 'Зміна пароля',
      company: 'Компанія',
      companies: 'Компанії',
      customers: 'Клієнти',
      employees: 'Працівники',
      sales: 'Продажі',
      settings: 'Налаштування',
      transactions: 'Зняття',
      users: 'Адміністратори',
    },
    events: {
      review: {
        title: 'Опублікований відгук на "%{product}"',
      },
      order: {
        title: 'Замовлено 1 постер |||| Замовлено %{smart_count} постерів',
      },
    },
  },
  resources: {
    dashboard: {
      amount: 'Сума',
      all_companies: 'Всі компанії',
      cards: {
        balance: 'Залишок',
        employees: 'Працівників',
        transactions: 'Зняттів',
        withdrawals: 'Всього знято',
      },
      choose_company: 'Компанія',
      choose_month: 'Місяць',
      choose_year: 'Рік',
      dashboard_empty: 'Немає даних',
      errors: {
        future_date: 'Ви не можете вибрати дату в майбутньому',
      },
      months: {
        january: 'Січень',
        february: 'Лютий',
        march: 'Березень',
        april: 'Квітень',
        may: 'Травень',
        june: 'Червень',
        july: 'Липень',
        august: 'Серпень',
        september: 'Вересень',
        october: 'Жовтень',
        november: 'Листопад',
        december: 'Грудень',
      },
      month_history: 'Динаміка зняттів',
      transactions: 'Зняттів',
    },
    company: {
      add_company_btn: 'Створити компанію',
      company: 'Компанія',
      company_empty_admin: 'У вас ще немає компаній',
      company_empty_super_admin: 'У вас ще немає компаній. Будь ласка, додайте нову компанію',
      status: 'Статус',
      balance: 'Баланс',
      last_update: 'Оновлено',
      number_of_employees: 'Кількість працівників',
      psps: 'Платіжні провайдери',
      enable_withdrawal_order: 'Генерація наказів',
      form: {
        add_company: {
          active: 'Активна',
          add_admin: 'Додати адміністратора',
          admin_email: 'Емейл адреса адміністратора',
          admin_last_name: 'Прізвище адміністратора',
          admin_name: "Ім'я адміністратора",
          admins: 'Адміністратори',
          budget: 'Бюджет',
          cancel: 'Скасувати',
          cancel_form: 'Закрити',
          card: 'Картка',
          cash: 'Готівка',
          clear_form: 'Очистити форму',
          company_bank_account: 'Банківський рахунок компанії',
          company_info: 'Інформація про компанію',
          company_name: 'Назва компанії',
          company_settings: 'Налаштування компанії',
          company_psp_list: 'Налаштування платіжної системи',
          contract: 'Договір',
          date_picker: 'Оберіть дні виплат*',
          date_picker_helper_text: 'ДД.ММ.РРРР',
          inactive: 'Неактивна',
          legal_entity_id: 'ЄДРПОУ',
          webhook_url: 'URL нотифікації роботодавця',
          psp_fee: 'Комісія Платіжної системи, %',
          service_fee: 'Комісія Сервісу, коп.',
          legal_name: 'Юридична назва',
          logo: 'Лого',
          manager_name: 'ПІБ Підписанта',
          manager_name_possessive: 'ПІБ Підписанта в Родовому відмінку',
          manager_role: 'Посада Підписанта',
          manager_role_possessive: 'Посада Підписанта в Родовому відмінку',
          merchant_id: 'Мерчант ID',
          merchant_info: 'Інформація про Мерчанта',
          merchant_key: 'Ключ Мерчанта',
          merchant_name: "Ім'я Мерчанта",
          merchant_balance_key: 'Ключ балансу Мерчанта',
          min_amount: 'Мінімальна сума',
          next_step: 'Наступний крок',
          number_of_paid_transactions: 'Кількість платних транзакцій',
          number_of_transactions: 'Кількість транзакцій',
          pay_day: 'День виплат',
          payment_days: 'Дні виплати',
          previous_step: 'Попередній крок',
          remove_admin: 'Видалити',
          save: 'Зберегти',
          upload_contract: 'Завантажити договір',
          upload_logo: 'Завантажити лого',
          upload_logo_rule: 'Будь ласка, завантажте логотип у співвідношенні 1:1 і не більше 5 МБ',
          withdrawal_limit: 'Ліміт зняття',
          withdrawal_method: 'Метод зняття',
        },
        add_company_errors: {
          admins_required: "Адмінітратор обов'язковий",
          admin_first_name_required: "Ім'я адміністратора обов'язкове",
          admin_email_required: "Емейл адміністратора обов'язковий",
          admin_last_name_required: "Прізвище адміністратора обов'язкове",
          company_bank_account_required: "Номер банку акаунта компанії обов'язковий",
          company_name_required: "Назва компанії обов'язкова",
          invalid_contract: 'Файл недійсний',
          invalid_email_address: 'Невірна емейл адреса',
          invalid_name: "Ім'я Мерчанта недійсне. Будь ласка, спробуйте ще раз",
          invalid_number: 'Неправильне число',
          invalid_merchant_id_or_key:
            'Ідентифікатор або Ключ Мерчанта недійсний. Будь ласка, спробуйте ще раз',
          legal_entity_id_max_value: 'ЄДРПОУ має містити не більше 8 символів',
          legal_entity_id_min_value: 'ЄДРПОУ має містити не менше 8 символів',
          legal_entity_id_number: 'ЄДРПОУ має складатися тільки з чисел',
          legal_name_required: "Юридична назва компанії обов'язкова",
          logo_large:
            'Файл завеликий або не є зображенням. Будь ласка, виберіть файл розміром менше 5 МБ і у форматі JPG/JPEG/PNG',
          manager_name_possessive_required: "ПІБ в Родовому відмінку обов'язкове",
          manager_name_required: "ПІБ Підписанта обов'язкове",
          manager_role_possessive_required: "Посада в Родовому відмінку обов'язкова",
          manager_role_required: "Посада Підписанта обов'язкова",
          merchant_empty: 'Інформація про Мерчанта відсутня. Будь ласка, заповніть дані Мерчанта',
          merchant_id_required: "Merchant ID обов'язкове",
          merchant_key_required: "Merchant key обов'язкове",
          merchant_name_contains_space:
            "Ім'я Мерчанта не може складатися з пробілів. Будь ласка використовуйте нижнє підкреслення",
          merchant_name_required: "Merchant name обов'язкове",
          min_amount_required: 'Мінімальна сума має бути позитивним числом',
          number_of_paid_transactions_required:
            'Кількість платних транзакцій має бути позитивним числом',
          number_of_transactions_required: 'Кількість транзакцій має бути позитивним числом',
          nullable_merchant_fields:
            'Ідентифікатор або Ключ Мерчанта недійсний. Будь ласка, спробуйте ще раз',
          required: "Це поле є обов'язковим",
          status_required: "Статус компанії обов'язковий",
          psp_required: "Платіжний провайдер обов'язковий",
          psp_fee_min: 'Комісія платіжної системи не може бути менше 0%',
          psp_fee_max: 'Комісія платіжної системи не може бути більше 99.99%',
          psp_fee_required: 'Комісія платіжної системи не може бути менше 0',
          secret_manager_key_exist: 'Такий мерчант вже існує',
          secret_merchant: 'Ключ Мерчанта недійсний. Будь ласка, спробуйте ще раз',
          service_fee_min: 'Комісія сервісу не може бути менше 0',
          service_fee_max: 'Комісія сервісу не може бути більше 50000',
          service_fee_required: 'Комісія сервісу не може бути менше 0',
          withdrawal_limit_exceeded: 'Ліміт зняття має бути не більше 100',
          withdrawal_limit_required: 'Ліміт зняття має бути позитивним числом',
          withdrawal_method_required: "Метод зняття обов'язковий",
          webhook_url_wrong_format: 'Неправильний формат URL',
          email_already_taken: 'Email адміністратора вже використовується в іншій компанії',
        },
      },
    },
    admins: {
      created_date: 'Дата реєстрації',
      admins_empty: 'Зараз у вас немає адміністраторів',
    },
    employee: {
      errors: {
        duplicate_itn_error:
          'Виникла помилка: файл містить %{field} РНОКПП номер співробітника що дублюється.',
        file_empty_fields_error: 'Виникла помилка: рядок %{field}, поле не може бути порожнім.',
        invalid_itn_format: 'Виникла помилка: рядок %{field}, невірний формат данних РНОКПП.',
        invalid_itn_length:
          'Виникла помилка: рядок %{field}, РНОКПП номер має складатися з 10 цифр.',
        invalid_salary_format:
          'Виникла помилка: рядок %{field}, невірний формат данних Середня зарплата.',
        required_column_not_exist:
          "Виникла помилка: Одна з обов'язкових колонок відсутня. Перевірте заголовки.",
      },
    },
    employees: {
      activate: 'Активувати',
      average_salary: 'Середня зарплата',
      choose_company: 'Оберіть свою компанію',
      choose_csv: 'Оберіть Excel файл',
      companies: 'Компанія',
      employees_empty: 'Зараз у вас немає співробітників',
      first_name: "Ім'я",
      import_csv: 'Додати співробітника',
      import_csv_modal_title: 'Додати співробітника',
      itn: 'РНОКПП',
      last_name: 'Прізвище',
      last_update: 'Оновлено',
      status: 'Статус',
      terminate: 'Призупинити',
      import_csv_status: {
        failure: 'Виникла помилка. Перевірте ваш документ і повторіть ваш запит пізніше',
        in_progress: 'Ваш файл оброблюється. Зачекайте на повідомлення про завершення',
        success: 'Співробітників успішно додано',
      },
    },
    transactions: {
      amount: 'Сума',
      companies: 'Компанія',
      createdAt: 'Дата створення',
      download_pdf: 'Заява ФД',
      withdrawal_order_pdf: 'Наказ',
      end_date: 'Кінцева дата',
      withdrawal_order_status: 'Статус наказу',
      export_csv: 'Експорт CSV',
      export_xls: 'Експорт XLS',
      sign_withdrawal_orders: 'Підписати всі накази',
      sign_withdrawal_orders_disabled: 'Генерація наказів не активована',
      first_name: "Ім'я",
      itn: 'РНОКПП',
      last_name: 'Прізвище',
      start_date: 'Початкова дата',
      status: 'Статус',
      transactions: 'Транзакції',
      transactions_empty: 'У вас ще немає транзакцій',
      withdrawal_order_dialog: {
        title: 'Підписання наказів',
        all_signed: 'Усі накази підписано',
        title_inner: 'Всі документи відправлені на підписання',
        warning_inner: 'Не закривайте це вікно, поки всі документи не підпишуться',
      },
    },
    customers: {
      name: 'Клієнт |||| Клієнти',
      fields: {
        commands: 'Замовлення',
        first_seen: 'Вперше замічений',
        full_name: "Повне ім'я",
        groups: 'Сегменти',
        last_seen: 'Останнє відвідування',
        last_seen_gte: 'Відвідував з',
        name: "Ім'я",
        total_spent: 'Всього витрачено',
        password: 'Пароль',
        confirm_password: 'Підтвердження пароля',
        stateAbbr: 'Штат',
      },
      filters: {
        last_visited: 'Останній візит',
        today: 'Сьогодні',
        this_week: 'Цього тижня',
        last_week: 'Минулого тижня',
        this_month: 'Цього місяця',
        last_month: 'Минулого місяця',
        earlier: 'Раніше',
        has_ordered: 'Зробив замовлення',
        has_newsletter: 'Отримує новини',
        group: 'Сегмент',
      },
      fieldGroups: {
        identity: 'Ідентичність',
        address: 'Адреса',
        stats: 'Статистика',
        history: 'Історія',
        password: 'Пароль',
        change_password: 'Змінити пароль',
      },
      page: {
        delete: 'Видалити клієнта',
      },
      errors: {
        password_mismatch: 'Пароль та підтвердження пароля не збігаються.',
      },
    },
    settings: {
      change_password: 'Змінити пароль',
      settings: 'Налаштування',
      change_password_form: {
        confirm_new_password: 'Підтвердити новий пароль',
        new_password: 'Новий пароль',
        old_password: 'Поточний пароль',
        password_changed: 'Пароль успішно змінений',
        required_field: "Це поле є обов'язковим",
        section_name: 'Змінити пароль',
        errors: {
          password_doesnt_match: 'Пароль не збігається',
          password_not_changed: 'Виникла помилка. Перевірте свій пароль та спробуйте ще раз',
          password_rule:
            'Пароль повинен містити мінімум 8 символів, один символ у верхньому регістрі, один символ у нижньому регістрі, одну цифру',
        },
      },
    },
    commands: {
      name: 'Замовлення |||| Замовлення',
      amount: '1 замовлення |||| %{smart_count} замовлень',
      title: 'Замовлення %{reference}',
      fields: {
        basket: {
          delivery: 'Доставка',
          reference: 'Референс',
          quantity: 'Кількість',
          sum: 'Сума',
          tax_rate: 'Податкова ставка',
          taxes: 'Податок',
          total: 'Всього',
          unit_price: 'Ціна за одиницю',
        },
        address: 'Адреса',
        customer_id: 'Клієнт',
        date_gte: 'Пройшло з',
        date_lte: 'Пройшло до',
        nb_items: 'Кількість товарів',
        total_gte: 'Мінімальна сума',
        status: 'Статус',
        returned: 'Повернуто',
      },
      section: {
        order: 'Замовлення',
        customer: 'Клієнт',
        shipping_address: 'Адреса доставки',
        items: 'Товари',
        total: 'Всього',
      },
    },
    invoices: {
      name: 'Рахунок-фактура |||| Рахунки-фактури',
      fields: {
        date: 'Дата рахунку-фактури',
        customer_id: 'Клієнт',
        command_id: 'Замовлення',
        date_gte: 'Пройшло з',
        date_lte: 'Пройшло до',
        total_gte: 'Мінімальна сума',
        address: 'Адреса',
      },
    },
    products: {
      name: 'Постер |||| Постери',
      fields: {
        category_id: 'Категорія',
        height_gte: 'Мінімальна висота',
        height_lte: 'Максимальна висота',
        height: 'Висота',
        image: 'Зображення',
        price: 'Ціна',
        reference: 'Референс',
        sales: 'Продажі',
        stock_lte: 'Малий запас',
        stock: 'Запас',
        thumbnail: 'Мініатюра',
        width_gte: 'Мінімальна ширина',
        width_lte: 'Максимальна ширина',
        width: 'Ширина',
      },
      tabs: {
        image: 'Зображення',
        details: 'Деталі',
        description: 'Опис',
        reviews: 'Відгуки',
      },
      filters: {
        categories: 'Категорії',
        stock: 'Запас',
        no_stock: 'Немає в наявності',
        low_stock: '1 - 9 одиниць',
        average_stock: '10 - 49 одиниць',
        enough_stock: '50 і більше одиниць',
        sales: 'Продажі',
        best_sellers: 'Найкращі продажі',
        average_sellers: 'Середні',
        low_sellers: 'Низькі',
        never_sold: 'Ніколи не продавались',
      },
    },
    categories: {
      name: 'Категорія |||| Категорії',
      fields: {
        products: 'Продукти',
      },
    },
    reviews: {
      name: 'Відгук |||| Відгуки',
      amount: '1 відгук |||| %{smart_count} відгуків',
      relative_to_poster: 'Відгук на постер',
      detail: 'Деталі відгуку',
      fields: {
        customer_id: 'Клієнт',
        command_id: 'Замовлення',
        product_id: 'Продукт',
        date_gte: 'Опубліковано з',
        date_lte: 'Опубліковано до',
        date: 'Дата',
        comment: 'Коментар',
        rating: 'Рейтинг',
      },
      action: {
        accept: 'Прийняти',
        reject: 'Відхилити',
      },
      notification: {
        approved_success: 'Дію затверджено',
        approved_error: 'Помилка: дія не затверджена',
        rejected_success: 'Дію відхилено',
        rejected_error: 'Помилка: дія не відхилена',
      },
    },
    segments: {
      name: 'Сегмент |||| Сегменти',
      fields: {
        customers: 'Клієнти',
        name: 'Назва',
      },
      data: {
        compulsive: 'Компульсивні',
        collector: 'Колекціонери',
        ordered_once: 'Замовляли один раз',
        regular: 'Регулярні',
        returns: 'Повернення',
        reviewer: 'Відгукодавці',
      },
    },
    psp: {
      psp_name: 'Платіжна система',
      status_active: 'Активна',
      status_not_active: 'Неактивна',
      info_tab: 'Інформація про PSP',
      credentials_tab: 'Облікові дані мерчанта',
      system_name: 'Назва',
      legal_name: 'Повна назва',
      company_vat: 'ІПН',
      save_psp: 'Зберегти',
      show_details: 'Перегляд',
      activate_psp: 'Активувати',
      back_to_company: 'Назад до компанії',
    },
  },
};

export default customUkrainianMessages;
