import { API_HANDLERS } from '@/api';
import { IPsp, IPspItemRequestParams } from '@/types';

export const getItem = async (requestParams: IPspItemRequestParams): Promise<{ data: IPsp }> => {
  try {
    const { data } = await API_HANDLERS.PSP.GET_ITEM_BY_ID(requestParams);

    return Promise.resolve({ data });
  } catch (error) {
    return Promise.reject(new Error(`Get Psp By Id Error: ${error}`));
  }
};
