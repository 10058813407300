import { ReactElement, useCallback } from 'react';
import { Button, Identifier, RaRecord, useRecordContext } from 'react-admin';

interface IListButton {
  title: string;
  disableSourceField?: string;
  onClick?: (record: RaRecord<Identifier>) => void;
  icon?: ReactElement;
}

function ListButton({ title, disableSourceField, onClick, icon }: IListButton) {
  const record = useRecordContext();

  const handleClick = useCallback(() => {
    onClick && onClick(record);
  }, []);

  return (
    <Button
      label={title}
      onClick={handleClick}
      disabled={disableSourceField && record[disableSourceField]}
    >
      {icon}
    </Button>
  );
}

export default ListButton;
