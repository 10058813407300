import { TabContext, TabPanel } from '@mui/lab';
import { Box, Typography, Tab, Tabs, TextField, Button } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { FormEventHandler, useCallback, useEffect, useState, type SyntheticEvent } from 'react';
import { type Identifier, ShowView, useTranslate, useGetRecordId, Link } from 'react-admin';

import { Loader } from '@/components/Loader';
import { companiesDataProvider } from '@/dataProvider/companiesDataProvider';
import { pspDataProvider } from '@/dataProvider/pspDataProvider';
import {
  CompanyEditFormMode,
  CompanyEditFormOptions,
  ICompanyCredentialsSecrets,
  IPsp,
} from '@/types';
import { PspUniqueName } from '@/constants';
import { isEqual } from 'lodash';

interface ICompanyPspViewProps {
  pspId?: Identifier;
  onChangeMode: (type: CompanyEditFormMode, options?: CompanyEditFormOptions) => void;
}

function CompanyPspView({ pspId, onChangeMode }: ICompanyPspViewProps): JSX.Element {
  const t = useTranslate();
  const companyId = useGetRecordId();

  const [loading, setLoading] = useState<boolean>(false);
  const [activeTab, setActiveTab] = useState<string>('info_tab');
  const [pspData, setPspData] = useState<IPsp>();
  const [credentialsSecrets, setCredentialsSecrets] = useState<ICompanyCredentialsSecrets>({
    merchant_id: '',
    merchant_key: '',
    merchant_balance_key: '',
  });

  const handleChangeTab = (_event: SyntheticEvent, newValue: string) => {
    setActiveTab(newValue);
  };

  const hangleGoBackToCompany = () => {
    onChangeMode('psp_list');
  };

  const onSubmit: FormEventHandler<HTMLFormElement> = async (event): Promise<void> => {
    event.preventDefault();

    if (!pspData) {
      return;
    }

    setLoading(true);

    const companyCredentials = await companiesDataProvider.getCredentialsList(companyId);
    const companyCreds = companyCredentials.find(item => item.psp_id === pspId);

    if (credentialsSecrets && !companyCreds) {
      await companiesDataProvider.createCredentials({
        ...credentialsSecrets,
        companyId,
        pspUniqueName: pspData.unique_name,
      });
    } else if (companyCreds && !isEqual(credentialsSecrets, companyCreds.secrets)) {
      await companiesDataProvider.editCredentials({
        ...credentialsSecrets,
        companyId,
        pspUniqueName: pspData.unique_name,
        credentialId: companyCreds.id,
      });
    }

    setLoading(false);
    hangleGoBackToCompany();
  };

  useEffect(() => {
    if (pspId) {
      pspDataProvider.getItem({ pspId }).then(({ data }) => setPspData(data));
      companiesDataProvider.getCredentialsList(companyId).then(data => {
        const creds = data.find(item => item.psp_id === pspId);
        if (creds?.secrets) {
          setCredentialsSecrets({
            ...credentialsSecrets,
            ...(creds.secrets || {}),
          });
        }
      });
    }
  }, [pspId, companyId]);

  const setCredentialsValue = useCallback(
    (field: keyof ICompanyCredentialsSecrets, value: string) => {
      setCredentialsSecrets({
        ...credentialsSecrets,
        [field]: value,
      });
    },
    [credentialsSecrets],
  );

  return (
    <>
      <Box
        sx={{
          margin: '20px 0',
        }}
      >
        <Link to="#" onClick={hangleGoBackToCompany} sx={{ display: 'flex' }}>
          <ArrowBackIcon />
          {t('resources.psp.back_to_company')}
        </Link>
      </Box>
      {!pspData ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          style={{ height: '90vh', width: '73vw' }}
        >
          <Loader />
        </Box>
      ) : (
        <ShowView
          sx={{
            maxWidth: '800px',
            width: '100%',
            '.MuiToolbar-root': {
              display: 'none',
            },
          }}
        >
          <TabContext value={activeTab}>
            <Tabs value={activeTab} onChange={handleChangeTab} aria-label="company form tabs">
              <Tab label={t('resources.psp.info_tab')} value="info_tab" />
              <Tab label={t('resources.psp.credentials_tab')} value="credentials_tab" />
            </Tabs>

            <TabPanel value="info_tab">
              <Box display={{ xs: 'block', sm: 'flex', width: '100%', marginBottom: '6px' }}>
                <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                  {t('resources.psp.system_name')}:
                </Box>
                <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                  <Typography variant="body1" component="p">
                    {pspData?.name}
                  </Typography>
                </Box>
              </Box>
              <Box display={{ xs: 'block', sm: 'flex', width: '100%', marginBottom: '6px' }}>
                <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                  {t('resources.psp.legal_name')}:
                </Box>
                <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                  <Typography variant="body1" component="p">
                    {pspData?.legal_name}
                  </Typography>
                </Box>
              </Box>
              <Box display={{ xs: 'block', sm: 'flex', width: '100%', marginBottom: '6px' }}>
                <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                  {t('resources.psp.company_vat')}:
                </Box>
                <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                  <Typography variant="body1" component="p">
                    {pspData?.vat_number}
                  </Typography>
                </Box>
              </Box>
            </TabPanel>

            <TabPanel value="credentials_tab">
              {pspId && (
                <form onSubmit={onSubmit}>
                  <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
                    <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                      <TextField
                        value={credentialsSecrets?.merchant_id}
                        onChange={e => setCredentialsValue('merchant_id', e.target.value)}
                        fullWidth
                        margin="normal"
                        label={t('resources.company.form.add_company.merchant_id')}
                        disabled={loading}
                      />
                    </Box>
                    <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                      <TextField
                        value={credentialsSecrets?.merchant_key}
                        onChange={e => setCredentialsValue('merchant_key', e.target.value)}
                        label={t('resources.company.form.add_company.merchant_key')}
                        fullWidth
                        margin="normal"
                        disabled={loading}
                      />
                    </Box>
                  </Box>
                  {pspData.unique_name === PspUniqueName.Platon && (
                    <Box display={{ xs: 'block', sm: 'flex', width: '50%' }}>
                      <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                        <TextField
                          value={credentialsSecrets?.merchant_balance_key}
                          onChange={e =>
                            setCredentialsValue('merchant_balance_key', e.target.value)
                          }
                          label={t('resources.company.form.add_company.merchant_balance_key')}
                          fullWidth
                          margin="normal"
                          disabled={loading}
                        />
                      </Box>
                    </Box>
                  )}

                  <Box sx={{ mt: 5, display: 'flex', justifyContent: 'end' }}>
                    <Button type="submit" variant="contained" disabled={loading}>
                      {t('resources.psp.save_psp')}
                    </Button>
                  </Box>
                </form>
              )}
            </TabPanel>
          </TabContext>
        </ShowView>
      )}
    </>
  );
}

export default CompanyPspView;
