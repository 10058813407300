import { KeyOutlined } from '@mui/icons-material';
import { Box } from '@mui/material';
import { Button, useTranslate } from 'react-admin';

import { companiesDataProvider } from '@/dataProvider/companiesDataProvider';
import { useGetUserRole } from '@/hooks';
import { useEffect, useState } from 'react';
import { RegistrationTypeEnum } from '@/types';

interface IProps {
  onClick: () => void;
}

function SignWithdrawalOrdersButton({ onClick }: IProps): JSX.Element {
  const userRole = useGetUserRole();
  const t = useTranslate();

  const [buttonState, setButtonState] = useState(true);

  useEffect(() => {
    if (userRole === RegistrationTypeEnum.COMPANY_ADMIN) {
      companiesDataProvider.getList().then(list => {
        if (!list.data?.[0]?.generate_withdrawal_order) {
          setButtonState(false);
        }
      });
    }
  }, [userRole]);

  const isButtonDisabled = userRole !== RegistrationTypeEnum.SUPER_ADMIN && !buttonState;

  return (
    <Box
      title={
        isButtonDisabled ? t('resources.transactions.sign_withdrawal_orders_disabled') : undefined
      }
    >
      <Button
        startIcon={<KeyOutlined />}
        label="resources.transactions.sign_withdrawal_orders"
        onClick={onClick}
        disabled={isButtonDisabled}
      />
    </Box>
  );
}

export default SignWithdrawalOrdersButton;
