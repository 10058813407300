import { TAddCompanyFormData } from '@/view/companies/CompaniesCreate/AddCompanyForm/AddCompanyForm';

export const formDefaultValues: TAddCompanyFormData = {
  admins: [{ first_name: '', last_name: '', email: '' }],
  company_name: '',
  contract_url: '',
  legal_entity_id: '',
  legal_name: '',
  logo: '',
  merchant_id: '',
  merchant_key: '',
  merchant_name: '',
  min_amount: 300,
  psp_fee: 0,
  service_fee: 0,
  number_of_transactions: 1,
  pay_days: [],
  withdrawal_limit: 50,
  withdrawal_method: 'Card',
  pspId: '',
};
