import { Identifier } from 'ra-core';

import { API_HANDLERS } from '@/api';
import { ICompanyPspItemResponseParams } from '@/types';

export const getPspList = async (
  companyId: Identifier,
): Promise<ICompanyPspItemResponseParams[]> => {
  try {
    const { data: responseData } = await API_HANDLERS.COMPANY.GET_PSP_LIST(companyId);

    return responseData;
  } catch (error) {
    return Promise.reject(new Error(`Get Company PSP List Error: ${error}`));
  }
};
