import { IPspPaths } from '@/types';
import { Identifier } from 'ra-core';

export const PSP_PATHS: IPspPaths = {
  LIST: {
    _: '/psp',
  },
  ITEM: {
    BY_ID: (pspId: Identifier) => `/psp/${pspId}`,
  },
} as const;
