import { TabContext, TabPanel } from '@mui/lab';
import { Box, Typography, Tab, Tabs, Link } from '@mui/material';
import { format } from 'date-fns';
import { useEffect, useState, type SyntheticEvent } from 'react';
import { type Identifier, ShowView, useTranslate, useGetRecordId } from 'react-admin';

import { Loader } from '@/components/Loader';
import { companiesDataProvider } from '@/dataProvider/companiesDataProvider';
import { ICompanyItemResponseParams } from '@/types';
import { PspUniqueName } from '@/constants';

function CompanyView() {
  const t = useTranslate();
  const recordId = useGetRecordId();

  const [activeTab, setActiveTab] = useState<string>('company_info');
  const [companyData, setCompanyData] = useState<ICompanyItemResponseParams>();

  const getCompanyItemById = async (companyId: Identifier): Promise<ICompanyItemResponseParams> => {
    const { data } = await companiesDataProvider.getItem({ companyId });
    setCompanyData(data);

    return data;
  };

  const handleChangeTab = (_event: SyntheticEvent, newValue: string) => {
    setActiveTab(newValue);
  };

  const lastUpdate = companyData?.last_update ? new Date(companyData?.last_update) : null;

  const lastUpdateString = !lastUpdate ? null : format(new Date(lastUpdate), 'dd.MM.yyyy HH:mm');

  useEffect(() => {
    if (recordId) {
      void getCompanyItemById(recordId);
    }
  }, [recordId]);

  return (
    <>
      {!companyData ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          style={{ height: '90vh', width: '73vw' }}
        >
          <Loader />
        </Box>
      ) : (
        <ShowView
          sx={{
            maxWidth: '800px',
            width: '100%',
          }}
          title={companyData?.company_name}
        >
          <TabContext value={activeTab}>
            <Tabs value={activeTab} onChange={handleChangeTab} aria-label="company form tabs">
              <Tab
                label={t('resources.company.form.add_company.company_info')}
                value="company_info"
              />
              <Tab
                label={t('resources.company.form.add_company.company_settings')}
                value="company_settings"
              />
            </Tabs>

            <TabPanel value="company_info">
              <Box display={{ xs: 'block', sm: 'flex', width: '100%', marginBottom: '6px' }}>
                <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                  {t('resources.company.form.add_company.legal_name')}:
                </Box>
                <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                  <Typography variant="body1" component="p">
                    {companyData?.legal_name}
                  </Typography>
                </Box>
              </Box>
              <Box display={{ xs: 'block', sm: 'flex', width: '100%', marginBottom: '6px' }}>
                <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                  {t('resources.company.form.add_company.company_name')}:
                </Box>
                <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                  <Typography variant="body1" component="p">
                    {companyData?.company_name}
                  </Typography>
                </Box>
              </Box>
              <Box display={{ xs: 'block', sm: 'flex', width: '100%', marginBottom: '6px' }}>
                <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                  {t('resources.company.form.add_company.legal_entity_id')}:
                </Box>
                <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                  <Typography variant="body1" component="p">
                    {companyData?.legal_entity_id}
                  </Typography>
                </Box>
              </Box>
              <Box display={{ xs: 'block', sm: 'flex', width: '100%', marginBottom: '6px' }}>
                <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                  {t('resources.company.form.add_company.psp_fee')}:
                </Box>
                <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                  <Typography variant="body1" component="p">
                    {companyData?.psp_fee}
                  </Typography>
                </Box>
              </Box>
              <Box display={{ xs: 'block', sm: 'flex', width: '100%', marginBottom: '6px' }}>
                <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                  {t('resources.company.form.add_company.service_fee')}:
                </Box>
                <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                  <Typography variant="body1" component="p">
                    {companyData?.service_fee}
                  </Typography>
                </Box>
              </Box>
              <Box display={{ xs: 'block', sm: 'flex', width: '100%', marginBottom: '6px' }}>
                <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                  {t('resources.company.enable_withdrawal_order')}:
                </Box>
                <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                  <Typography variant="body1" component="p">
                    {companyData?.generate_withdrawal_order ? t('common.yes') : t('common.no')}
                  </Typography>
                </Box>
              </Box>
              <Box display={{ xs: 'block', sm: 'flex', width: '100%', marginBottom: '6px' }}>
                <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                  {t('resources.company.form.add_company.webhook_url')}:
                </Box>
                <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                  <Typography variant="body1" component="p">
                    {companyData?.webhook_url}
                  </Typography>
                </Box>
              </Box>

              {companyData?.contract_url ? (
                <>
                  <Typography
                    variant="subtitle1"
                    component="h6"
                    sx={{ fontWeight: 'bold', marginBottom: '8px', marginTop: '24px' }}
                  >
                    {t('resources.company.form.add_company.contract')}
                  </Typography>
                  <Box sx={{ marginBottom: '24px' }}>
                    <Link
                      href={companyData?.contract_url}
                      target="_blank"
                      rel="noopener"
                      variant="subtitle1"
                      sx={{ mb: 2 }}
                    >
                      {`${t('resources.company.form.add_company.contract')}.pdf`}
                    </Link>
                  </Box>
                </>
              ) : null}

              {companyData?.admins?.length ? (
                <Typography
                  variant="subtitle1"
                  component="h6"
                  sx={{ fontWeight: 'bold', marginBottom: '8px', marginTop: '24px' }}
                >
                  Адміністратори
                </Typography>
              ) : null}
              {companyData?.admins?.length ? (
                <Box sx={{ marginBottom: '24px' }}>
                  {companyData?.admins?.map((item, index) => {
                    return (
                      <>
                        <Box
                          key={index}
                          display={{ xs: 'block', sm: 'flex', width: '100%', marginBottom: '6px' }}
                        >
                          <Box flex={1}>
                            <Typography variant="body1" component="p">
                              {item?.first_name} {item?.last_name}
                            </Typography>
                          </Box>

                          <Box flex={1}>
                            <Typography variant="body1" component="p">
                              {item?.email}
                            </Typography>
                          </Box>
                        </Box>
                      </>
                    );
                  })}
                </Box>
              ) : null}
              <Box display={{ xs: 'block', sm: 'flex', width: '100%', marginBottom: '6px' }}>
                <Box flex={1}>{t('resources.employees.last_update')}:&nbsp;</Box>
                <Box flex={1}>{lastUpdateString}</Box>
              </Box>

              <Box display={{ xs: 'block', sm: 'flex', width: '100%', marginBottom: '6px' }}>
                <Box flex={1}>{t('resources.company.form.add_company.merchant_id')}:&nbsp;</Box>
                <Box flex={1}>
                  <Typography variant="body1" component="p">
                    {companyData?.merchant_id}
                  </Typography>
                </Box>
              </Box>
              <Box display={{ xs: 'block', sm: 'flex', width: '100%', marginBottom: '6px' }}>
                <Box flex={1}>{t('resources.company.form.add_company.merchant_key')}:&nbsp;</Box>
                <Box flex={1}>
                  <Typography variant="body1" component="p">
                    {companyData?.merchant_key}
                  </Typography>
                </Box>
              </Box>
              {companyData?.psp?.unique_name === PspUniqueName.Platon && (
                <Box display={{ xs: 'block', sm: 'flex', width: '100%', marginBottom: '6px' }}>
                  <Box flex={1}>
                    {t('resources.company.form.add_company.merchant_balance_key')}:&nbsp;
                  </Box>
                  <Box flex={1}>
                    <Typography variant="body1" component="p">
                      {companyData?.merchant_balance_key}
                    </Typography>
                  </Box>
                </Box>
              )}
              <Box display={{ xs: 'block', sm: 'flex', width: '100%', marginBottom: '6px' }}>
                <Box flex={1}>{t('resources.company.form.add_company.merchant_name')}:&nbsp;</Box>
                <Box flex={1}>
                  <Typography variant="body1" component="p">
                    {companyData?.psp?.name}
                  </Typography>
                </Box>
              </Box>
              <Box display={{ xs: 'block', sm: 'flex', width: '100%', marginBottom: '6px' }}>
                <Box flex={1}>{t('resources.company.status')}:&nbsp;</Box>
                <Box flex={1}>
                  <Typography variant="body1" component="p">
                    {t(`resources.company.form.add_company.${companyData?.status.toLowerCase()}`)}
                  </Typography>
                </Box>
              </Box>
            </TabPanel>

            <TabPanel value="company_settings">
              <Box
                display={{ xs: 'block', sm: 'block', width: '100%' }}
                sx={{
                  padding: '20px',
                }}
              >
                <Box display={{ xs: 'block', sm: 'flex', width: '100%', marginBottom: '6px' }}>
                  <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                    {t('resources.company.balance')}:&nbsp;
                  </Box>
                  <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                    <Typography variant="body1" component="p">
                      {companyData?.balance} грн
                    </Typography>
                  </Box>
                </Box>
                <Box display={{ xs: 'block', sm: 'flex', width: '100%', marginBottom: '6px' }}>
                  <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                    {t('resources.company.form.add_company.withdrawal_limit')}:&nbsp;
                  </Box>
                  <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                    <Typography variant="body1" component="p">
                      {companyData?.withdrawal_limit}%
                    </Typography>
                  </Box>
                </Box>
                <Box display={{ xs: 'block', sm: 'flex', width: '100%', marginBottom: '6px' }}>
                  <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                    {t('resources.company.form.add_company.min_amount')}:&nbsp;
                  </Box>
                  <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                    <Typography variant="body1" component="p">
                      {companyData?.min_amount} грн
                    </Typography>
                  </Box>
                </Box>
                <Box display={{ xs: 'block', sm: 'flex', width: '100%', marginBottom: '6px' }}>
                  <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                    {t('resources.company.form.add_company.number_of_transactions')}:&nbsp;
                  </Box>
                  <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                    <Typography variant="body1" component="p">
                      {companyData?.number_of_transactions}
                    </Typography>
                  </Box>
                </Box>
                <Box display={{ xs: 'block', sm: 'flex', width: '100%', marginBottom: '6px' }}>
                  <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                    {t('resources.company.form.add_company.withdrawal_method')}:&nbsp;
                  </Box>
                  <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                    <Typography variant="body1" component="p">
                      {companyData?.withdrawal_method === 'Card'
                        ? t('resources.company.form.add_company.card')
                        : companyData?.withdrawal_method}
                    </Typography>
                  </Box>
                </Box>
                <Box display={{ xs: 'block', sm: 'flex', width: '100%', marginBottom: '6px' }}>
                  <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                    {t('resources.company.form.add_company.pay_day')}:&nbsp;
                  </Box>
                  <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                    <Typography
                      variant="body1"
                      component="p"
                      sx={{ display: 'flex', flexWrap: 'wrap', marginBottom: '0' }}
                    >
                      {companyData?.pay_days?.map((item, index) => {
                        const date = new Date(item);

                        return (
                          <Box key={index} component="span" sx={{ marginRight: '6px' }}>
                            {!date ? null : (
                              <>
                                <Typography
                                  variant="body1"
                                  component="span"
                                  sx={{ marginBottom: '0', display: 'block', marginRight: '4px' }}
                                >
                                  {format(date, 'dd.MM.yyyy')}

                                  {/* eslint-disable-next-line no-restricted-syntax */}
                                  {index < companyData.pay_days.length - 1 && ','}
                                </Typography>
                              </>
                            )}
                          </Box>
                        );
                      })}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </TabPanel>
          </TabContext>
        </ShowView>
      )}
    </>
  );
}

export default CompanyView;
