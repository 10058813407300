import { API_PATHS } from '@/api';
import { getData } from '@/api/crudHandlers';
import { IPsp, IPspItemRequestParams, TAxiosResponseSuccess } from '@/types';

export const pspHandlers = {
  GET_LIST(): TAxiosResponseSuccess<IPsp[]> {
    return getData<IPsp[]>(API_PATHS.PSP.LIST._);
  },
  GET_ITEM_BY_ID(data: IPspItemRequestParams): TAxiosResponseSuccess<IPsp> {
    return getData<IPsp>(API_PATHS.PSP.ITEM.BY_ID(data.pspId));
  },
};
