import { Box } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { type Identifier, useGetRecordId } from 'react-admin';

import { Loader } from '@/components/Loader';
import { companiesDataProvider } from '@/dataProvider';
import { CompanyEditFormMode, CompanyEditFormOptions, ICompanyItemResponseParams } from '@/types';
import { EditCompanyForm } from '@/view/companies/CompaniesEdit/components/form/EditCompanyForm';
import { CompanyPspView } from '@/view/companies/CompaniesEdit/components/form/CompanyPspView';

function CompaniesEdit(): JSX.Element {
  const recordId = useGetRecordId();

  const [companyData, setCompanyData] = useState<ICompanyItemResponseParams>();
  const [editFormMode, setEditFormMode] = useState<CompanyEditFormMode>('company_edit');
  const [editFormOptions, setEditFormOptions] = useState<CompanyEditFormOptions>({});

  const handleChangeEditFormMode = useCallback(
    (mode: CompanyEditFormMode, options: CompanyEditFormOptions = {}) => {
      setEditFormMode(mode);
      setEditFormOptions(options);
    },
    [editFormMode, editFormOptions],
  );

  const getCompanyItemById = async (companyId: Identifier): Promise<ICompanyItemResponseParams> => {
    const { data } = await companiesDataProvider.getItem({ companyId });
    setCompanyData(data);

    return data;
  };

  useEffect(() => {
    if (recordId) {
      void getCompanyItemById(recordId);
    }
  }, [recordId]);

  return (
    <>
      {!companyData ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          style={{ height: '90vh', width: '73vw' }}
        >
          <Loader />
        </Box>
      ) : (
        <Box>
          {['company_edit', 'psp_list'].includes(editFormMode) && (
            <EditCompanyForm
              formDefaultValues={companyData}
              companyId={recordId}
              editFormMode={editFormMode}
              onChangeMode={handleChangeEditFormMode}
            />
          )}
          {editFormMode === 'psp' && (
            <CompanyPspView pspId={editFormOptions.pspId} onChangeMode={handleChangeEditFormMode} />
          )}
        </Box>
      )}
    </>
  );
}

export default CompaniesEdit;
