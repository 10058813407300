import englishMessages from 'ra-language-english';
import { TranslationMessages } from 'react-admin';

const customEnglishMessages: TranslationMessages = {
  ...englishMessages,
  ra: {
    ...englishMessages.ra,
    page: {
      ...englishMessages.ra.page,
      dashboard: 'Dashboard',
    },
  },
  auth: {
    ...englishMessages.ra.auth,
    email: 'Enter email address',
    forgot_password: 'Forgot password',
    password: 'Enter password',
    sign_in: 'Sign In',
    errors: {
      email: 'The Email field must contain an email.',
      email_not_empty: "The Email field can't be empty.",
      otp_attempts_exceeded: 'You have exceeded your request limit. Contact the support service.',
      password_symbol_validation:
        'The password must contain at least one character in uppercase, lowercase, and a number.',
      password_min_length: 'The password must contain at least 8 characters.',
      password_not_empty: "The Password field can't be empty.",
      password_not_valid: 'Incorrect password.',
      user_not_found: 'No such user found. Check the entered data.',
    },
  },
  forgot_password: {
    new_password: 'New password',
    confirm_new_password: 'Confirm new password',
    change_password_btn: 'Change password',
    confirm_email_btn: 'Confirm email',
    errors: {
      required_field: 'This field is required',
      invalid_email: 'Invalid email. Check your data and try again',
      invalid_email_address: 'Invalid email address',
      invalid_password: 'Invalid password',
      password_doesnt_match: "Password doesn't match",
      valid_email: 'The email address is valid. We sent you a letter with instructions.',
      valid_password: 'Valid password',
    },
  },
  labels: {
    email: 'Email Address',
  },
  pos: {
    cancel: 'Cancel',
    close: 'Close',
    search: 'Search',
    configuration: 'Configuration',
    language: 'Language',
    theme: {
      name: 'Theme',
      light: 'Light',
      dark: 'Dark',
    },
    dashboard: {
      monthly_revenue: 'Monthly Revenue',
      month_history: '30 Day Revenue History',
      new_orders: 'New Orders',
      pending_reviews: 'Pending Reviews',
      all_reviews: 'See all reviews',
      new_customers: 'New Customers',
      all_customers: 'See all customers',
      pending_orders: 'Pending Orders',
      order: {
        items: 'by %{customer_name}, one item |||| by %{customer_name}, %{nb_items} items',
      },
      welcome: {
        title: 'Welcome to the react-admin e-commerce demo',
        subtitle:
          "This is the admin of an imaginary poster shop. Feel free to explore and modify the data - it's local to your computer, and will reset each time you reload.",
        ra_button: 'react-admin site',
        demo_button: 'Source for this demo',
      },
    },
    menu: {
      catalog: 'Catalog',
      change_password: 'Change password',
      company: 'Company',
      companies: 'Companies',
      customers: 'Customers',
      employees: 'Employees',
      sales: 'Sales',
      settings: 'Settings',
      transactions: 'Transactions',
      users: 'Admins',
    },
    events: {
      review: {
        title: 'Posted review on "%{product}"',
      },
      order: {
        title: 'Ordered 1 poster |||| Ordered %{smart_count} posters',
      },
    },
  },
  resources: {
    dashboard: {
      amount: 'Amount',
      all_companies: 'All companies',
      cards: {
        balance: 'Balance',
        employees: 'Employees',
        transactions: 'Transactions',
        withdrawals: 'Total withdrawals',
      },
      choose_company: 'Choose company',
      choose_month: 'Choose month',
      choose_year: 'Choose year',
      dashboard_empty:
        'The dashboard is empty. Try to choose valid data or contact technical support',
      errors: {
        future_date: 'End date cannot be in the future',
      },
      months: {
        january: 'January',
        february: 'February',
        march: 'March',
        april: 'April',
        may: 'May',
        june: 'June',
        july: 'July',
        august: 'August',
        september: 'September',
        october: 'October',
        november: 'November',
        december: 'December',
      },
      month_history: 'Month history',
      transactions: 'Transactions',
    },
    company: {
      add_company_btn: 'Add Company',
      company: 'Company',
      company_empty_admin: "You don't have any companies yet",
      company_empty_super_admin: "You don't have any companies yet. Please add a new company",
      status: 'Status',
      last_update: 'Last Update',
      balance: 'Balance',
      number_of_employees: 'Number of Employees',
      psps: 'Payment providers',
      form: {
        add_company: {
          active: 'Active',
          add_admin: 'Add Admin',
          admin_email: "Administrator's Email Address",
          admin_last_name: "Administrator's Last Name",
          admin_name: "Administrator's Name",
          admins: 'Administrators',
          budget: 'Budget',
          cancel: 'Cancel',
          cancel_form: 'Cancel',
          card: 'Card',
          cash: 'Cash',
          clear_form: 'Clear Form',
          company_bank_account: 'Company Bank Account Number',
          company_info: 'Company Information',
          company_name: 'Company Name',
          company_settings: 'Company Settings',
          company_psp_list: 'PSP List',
          contract: 'Contract',
          date_picker: 'Select Pay Days*',
          date_picker_helper_text: 'DD.MM.YYYY',
          inactive: 'Inactive',
          legal_entity_id: 'Legal entity id',
          legal_name: 'Legal Name',
          logo: 'Logo',
          manager_name: "Manager's Name",
          manager_name_possessive: 'Name of the Manager in the Genitive case',
          manager_role: 'Manager Role',
          manager_role_possessive: 'Role of the Manager in the Genitive case',
          merchant_id: 'Merchant ID',
          merchant_info: 'Merchant Information',
          merchant_key: 'Merchant Key',
          merchant_name: 'Merchant Name',
          merchant_balance_key: 'Merchant balance key',
          min_amount: 'Minimum Amount',
          next_step: 'Next Step',
          number_of_paid_transactions: 'Number of Paid Transactions',
          number_of_transactions: 'Number of Transactions',
          pay_day: 'Pay Day',
          payment_days: 'Payment Days',
          previous_step: 'Previous Step',
          remove_admin: 'Remove',
          save: 'Save',
          upload_contract: 'Upload contract',
          upload_logo: 'Upload logo',
          upload_logo_rule: 'Please upload logo in 1:1 ratio 5mB max',
          withdrawal_limit: 'Withdrawal Limit',
          withdrawal_method: 'Withdrawal Method',
          psp_fee: 'PSP fee',
          service_fee: 'Service fee',
          webhook_url: 'Webhook url',
        },
        add_company_errors: {
          admins_required: 'Administrator is required',
          admin_first_name_required: "Administrator's first name is required",
          admin_email_required: "Administrator's email is required",
          admin_last_name_required: "Administrator's last name is required",
          company_bank_account_required: 'Company bank account number is required',
          company_name_required: 'Company name is required',
          invalid_contract: 'Invalid contract',
          invalid_email_address: 'Invalid email address',
          invalid_name: 'Merchant name is invalid. Please try again',
          invalid_number: 'Invalid number',
          invalid_merchant_id_or_key: 'The Merchant ID or Key is invalid. Please try again',
          legal_entity_id_max_value: 'Legal entity id must contain no more than 8 characters',
          legal_entity_id_min_value: 'Legal entity id must contain 8 characters',
          legal_entity_id_number: 'Legal entity id should consist only of numbers',
          legal_name_required: 'Legal company name is required',
          logo_large:
            'File is too large or not an image. Please choose a file less than 5MB and in JPG/JPEG/PNG format',
          manager_name_possessive_required: 'Full name in genitive case is required',
          manager_name_required: 'Manager name is required',
          manager_role_possessive_required: 'The role in the Genitive case is required',
          manager_role_required: 'Manager role is required',
          merchant_empty:
            'There is no information about the Merchant. Please fill in the details of the Merchant',
          merchant_id_required: 'Merchant ID is required',
          merchant_key_required: 'Merchant key is required',
          merchant_name_contains_space:
            'The Merchant name cannot contain spaces. Please use the underline',
          merchant_name_required: 'Merchant name is required',
          min_amount_required: 'Minimum amount must be a positive number',
          nullable_merchant_fields: 'The Merchant ID or Key is invalid. Please try again',
          number_of_paid_transactions_required:
            'Number of paid transactions must be a positive number',
          number_of_transactions_required: 'Number of transactions must be a positive number',
          required: 'This field is required',
          status_required: 'Company status is required',
          psp_required: 'Company PSP is required',
          psp_fee_min: 'Psp fee should not be less then 0%',
          psp_fee_max: 'Psp fee should be less then 99.99%',
          psp_fee_required: 'Psp fee should not be less then 0',
          service_fee_required: 'Psp fee should not be less then 0',
          secret_manager_key_exist: 'Such a merchant already exists',
          secret_merchant: 'Merchant key is invalid. Please try again',
          service_fee_min: 'Psp fee should not be less then 0',
          withdrawal_limit_exceeded: 'Withdrawal limit should be no more than 100',
          withdrawal_limit_required: 'Withdrawal limit must be a positive number',
          withdrawal_method_required: 'Withdrawal method is required',
          webhook_url_wrong_format: 'Invalid URL format',
        },
      },
    },
    admins: {
      created_date: 'Registration Date',
      admins_empty: 'Зараз у вас немає адміністраторів',
    },
    employee: {
      errors: {
        duplicate_itn_error:
          'An error occurred: the file contains the TIN number %{field} of the employee that is duplicated.',
        file_empty_fields_error: 'An error occurred: the field %{field} cannot be empty.',
        invalid_itn_format: 'An error occurred: incorrect format %{field} of TIN data.',
        invalid_itn_length: 'An error occurred: the TIN number %{field} must consist of 10 digits.',
        invalid_salary_format: 'An error occurred: incorrect data format %{field} Average salary.',
        required_column_not_exist: 'An error occurred: column %{field} is missing.',
      },
    },
    employees: {
      first_name: 'First Name',
      activate: 'Activate',
      average_salary: 'Average Salary',
      choose_company: 'Choose your company',
      choose_csv: 'Choose Excel file',
      companies: 'Company',
      employees_empty: 'You don’t have any employees now',
      import_csv: 'Add Employee',
      import_csv_modal_title: 'Add Employee',
      itn: 'ITN',
      last_name: 'Last Name',
      last_update: 'Last Update',
      status: 'Status',
      terminate: 'Terminate',
      import_csv_status: {
        failure: 'An error occurred. Check your document and repeat your request later',
        in_progress: 'Your file is being processed. Wait for the completion message',
        success: 'Your employees have been successfully added',
      },
    },
    transactions: {
      amount: 'Amount',
      companies: 'Company',
      createdAt: 'Created at',
      download_pdf: 'Download PDF',
      withdrawal_order_pdf: 'Withdrawal order',
      end_date: 'End date',
      withdrawal_order_status: 'Withdrawal order status',
      export_csv: 'Export CSV',
      export_xls: 'Export XLS',
      sign_withdrawal_orders: 'Sign all withdrawal orders',
      first_name: 'First name',
      itn: 'ITN',
      last_name: 'Last name',
      start_date: 'Start date',
      status: 'Status',
      transactions: 'Transactions',
      transactions_empty: 'You don’t have any transactions yet',
      withdrawal_order_dialog: {
        title: 'Orders signing',
        all_signed: 'Orders have been signed',
        title_inner: 'All documents have been sent for signing',
        warning_inner: 'Do not close this window until all documents are signed',
      },
    },
    customers: {
      name: 'Customer |||| Customers',
      fields: {
        commands: 'Orders',
        first_seen: 'First seen',
        full_name: 'Name',
        groups: 'Segments',
        last_seen: 'Last seen',
        last_seen_gte: 'Visited Since',
        name: 'Name',
        total_spent: 'Total spent',
        password: 'Password',
        confirm_password: 'Confirm password',
        stateAbbr: 'State',
      },
      filters: {
        last_visited: 'Last visited',
        today: 'Today',
        this_week: 'This week',
        last_week: 'Last week',
        this_month: 'This month',
        last_month: 'Last month',
        earlier: 'Earlier',
        has_ordered: 'Has ordered',
        has_newsletter: 'Has newsletter',
        group: 'Segment',
      },
      fieldGroups: {
        identity: 'Identity',
        address: 'Address',
        stats: 'Stats',
        history: 'History',
        password: 'Password',
        change_password: 'Change Password',
      },
      page: {
        delete: 'Delete Customer',
      },
      errors: {
        password_mismatch: 'The password confirmation is not the same as the password.',
      },
    },
    settings: {
      change_password: 'Change password',
      settings: 'Settings',
      change_password_form: {
        confirm_new_password: 'Confirm new password',
        new_password: 'New password',
        old_password: 'Old password',
        password_changed: 'Password successfully changed',
        required_field: 'This field is required',
        section_name: 'Password change',
        errors: {
          password_doesnt_match: "Password doesn't match",
          password_not_changed: 'An error occurred. Please check your password and try again',
          password_rule:
            'Password must contain at least 8 characters, one uppercase character, one lowercase character, one number',
        },
      },
    },
    commands: {
      name: 'Order |||| Orders',
      amount: '1 order |||| %{smart_count} orders',
      title: 'Order %{reference}',
      fields: {
        basket: {
          delivery: 'Delivery',
          reference: 'Reference',
          quantity: 'Quantity',
          sum: 'Sum',
          tax_rate: 'Tax Rate',
          taxes: 'Tax',
          total: 'Total',
          unit_price: 'Unit Price',
        },
        address: 'Address',
        customer_id: 'Customer',
        date_gte: 'Passed Since',
        date_lte: 'Passed Before',
        nb_items: 'Nb Items',
        total_gte: 'Min amount',
        status: 'Status',
        returned: 'Returned',
      },
      section: {
        order: 'Order',
        customer: 'Customer',
        shipping_address: 'Shipping Address',
        items: 'Items',
        total: 'Totals',
      },
    },
    invoices: {
      name: 'Invoice |||| Invoices',
      fields: {
        date: 'Invoice date',
        customer_id: 'Customer',
        command_id: 'Order',
        date_gte: 'Passed Since',
        date_lte: 'Passed Before',
        total_gte: 'Min amount',
        address: 'Address',
      },
    },
    products: {
      name: 'Poster |||| Posters',
      fields: {
        category_id: 'Category',
        height_gte: 'Min height',
        height_lte: 'Max height',
        height: 'Height',
        image: 'Image',
        price: 'Price',
        reference: 'Reference',
        sales: 'Sales',
        stock_lte: 'Low Stock',
        stock: 'Stock',
        thumbnail: 'Thumbnail',
        width_gte: 'Min width',
        width_lte: 'Max width',
        width: 'Width',
      },
      tabs: {
        image: 'Image',
        details: 'Details',
        description: 'Description',
        reviews: 'Reviews',
      },
      filters: {
        categories: 'Categories',
        stock: 'Stock',
        no_stock: 'Out of stock',
        low_stock: '1 - 9 items',
        average_stock: '10 - 49 items',
        enough_stock: '50 items & more',
        sales: 'Sales',
        best_sellers: 'Best sellers',
        average_sellers: 'Average',
        low_sellers: 'Low',
        never_sold: 'Never sold',
      },
    },
    categories: {
      name: 'Category |||| Categories',
      fields: {
        products: 'Products',
      },
    },
    reviews: {
      name: 'Review |||| Reviews',
      amount: '1 review |||| %{smart_count} reviews',
      relative_to_poster: 'Review on poster',
      detail: 'Review detail',
      fields: {
        customer_id: 'Customer',
        command_id: 'Order',
        product_id: 'Product',
        date_gte: 'Posted since',
        date_lte: 'Posted before',
        date: 'Date',
        comment: 'Comment',
        rating: 'Rating',
      },
      action: {
        accept: 'Accept',
        reject: 'Reject',
      },
      notification: {
        approved_success: 'Review approved',
        approved_error: 'Error: Review not approved',
        rejected_success: 'Review rejected',
        rejected_error: 'Error: Review not rejected',
      },
    },
    segments: {
      name: 'Segment |||| Segments',
      fields: {
        customers: 'Customers',
        name: 'Name',
      },
      data: {
        compulsive: 'Compulsive',
        collector: 'Collector',
        ordered_once: 'Ordered once',
        regular: 'Regular',
        returns: 'Returns',
        reviewer: 'Reviewer',
      },
    },
    psp: {
      psp_name: 'PSP',
      status_active: 'Active',
      status_not_active: 'Not active',
      info_tab: 'PSP information',
      credentials_tab: 'PSP credentials',
      system_name: 'Name',
      legal_name: 'Legal name',
      company_vat: 'VAT',
      save_psp: 'Save',
      show_details: 'Details',
      activate_psp: 'Activate',
    },
  },
};

export default customEnglishMessages;
